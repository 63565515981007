


/* blogs page start*/

.blogs ,.blogs__list{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.blog{
  position: relative;
  
}



.post-svg {
  position: absolute;
  top: 2%;
  
  right: 7%;
  
}

.blog-container {
  position: relative;
  padding: 23% 18% 21% 9%;
  border-top: 0.7px solid white;
  border-top-width: 0.7px;
  border-top-style: solid;
  border-top-color: white;
  border-right: 0.7px solid white;
  border-bottom: 0.5px solid white;
  background-color: black;
}
.blog__item-title a{
  font-size: 18px;
  text-decoration: none;
  line-height: 23px;
  letter-spacing: 0.79px;
}
.author-and-date{
  padding-top: 3%;
  margin-bottom: 10%;
}
.PageNavigation{
  margin-bottom: 8%;
}
.blog__item-number:after {
  position: absolute;
  height: 2%;
  background: white;
  content: '';
  width: 89px;
  top: 0;
  left: 0;
}

.blog__post {
  display: flex;
  justify-content: space-between;
  
}



@media (min-width: 769px) {
  
  
  .blog__title-container {
    display: flex;
    margin-top: 5%;
    
  }
  .blog__title-container ul {
    list-style: decimal;
    padding: 1rem 0 1rem 1rem;
  }
  .blog__lower__index-svg {
    position: absolute;
    top: 34%;
    left: 0;
    left: -37%;
  }
  .blogs__list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .more-articles{
    padding: 5% 0 5% 0;
  }
  
}